<template>
    <div>
         <div class="isheader">
             <span class="is-span-header go" @click="$router.push('/Quality')"></span>
             <span class="is-span-header">新增运送批次</span>
             <span class="is-span-header" @click="islogout">退出</span>
        </div>
        <div class="content">
            <van-form  label-width='130' label-align="right">
                <van-field  v-model="from.batch_number" label="运输批次号：" 
                :rules="[{ required: true, message: '请填运输批次号' }]" placeholder="请填写运输批次号" />
                <van-field v-model="from.carNumberPlate" label="运输大车车牌：" placeholder="请填写运输大车车牌" />
                <van-field
                    readonly
                    clickable
                    name="picker"
                    v-model="purchaseName"
                    label="采购类型："
                    placeholder="点击选择采购类型"
                    @click="showPicker = true"
                    />
                    <van-popup v-model="showPicker" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="columns"
                        value-key="name"
                        @confirm="onConfirm"
                        @cancel="showPicker = false"
                    />
                    </van-popup>
                    <!-- v-if="this.from.purchaseType !== 1" -->
                <van-field
                    v-if="from.purchaseType != 1"
                    readonly
                    clickable
                    name="picker"
                    v-model="ChanneNmae"
                    label="选择采购渠道："
                    placeholder="选择采购渠道"
                    @click="show = true"
                    >
                     <template #button>
                        <van-button size="small" @click.stop="addChangeHan" type="info">新增渠道</van-button>
                    </template>
                    </van-field>
                    <van-popup v-model="show" position="bottom"  :close-on-click-overlay="false">
                    <van-picker
                        show-toolbar
                        :columns="ChanneList"
                        value-key="shopName"
                        @confirm="onConfirm1"
                        @cancel="show = false"
                    />
                    </van-popup>
                    <van-field name="uploader" label="大车照：" v-if="!isWechat">
                        <template #input>
                            <van-uploader class="imgUp" v-model="fileList1" :after-read="uploadershang" :before-delete="afterDelete" :max-count="1" >
                                <van-button size="small" class="upload-p-btn" type="primary">大车照</van-button>
                            </van-uploader>
                        </template>
                    </van-field>
                    <div v-else>
                        <p style="margin-left:70px">大车照：</p>
                        <div style="text-align: center;">

                            <span v-if="!from.image" class="uploaderImg" @click="WeChooseImage(1,'bigCar')"> 
                                <van-icon class="iconz" name="plus" />
                            </span>
                            <div v-if="from.image" style="width:100%">
                                <van-image class="vanImage" style="width:100%"  :src="from.image" @click="bigImg(from.image)" >
                                    <van-icon class="iconX" name="close" @click.stop="delImgHan('bigCar')" />
                                </van-image>
                            </div>
                        </div>
                    </div>
                    <van-button class="btn-s" round block type="info" @click="onSubmit">提交</van-button>
            </van-form>
           
        </div>
          <van-popup
            v-model="isAddShow"
            :closeable="false"
            :close-on-click-overlay="false"
            position="bottom"
            :style="{
                height: '100%',
            }"
        >
            <AddChannel ref="Channels" @addditch="addditch"  />
    </van-popup>
    </div>
</template>

<script>
import { is_post } from "../../Http/api";
import AddChannel from './components/AddChannel.vue'
import { encipherMent, encryptDecode } from '@/utils/encrypt'
import { Toast } from 'vant';
    export default {
        components: {
            AddChannel,
        },
        data() {
            return {
                from:{
                    batch_number: '',//批次号
                    carNumberPlate: '',//车牌号
                    purchaseType: 1,//采购渠道类型 1普通采购2渠道采购
                    purchasingChannelsId: '',//采购渠道id
                    image:"",
                },
                fileList1:[],
                purchaseName:'普通采购',//采购选择汉子
                showPicker:false,//采购选择弹窗
                show:false,//渠道弹窗
                isAddShow:false,//新增渠道
                ChanneList:[],//渠道列表
                ChanneNmae:'',//渠道列表
                
                columns: [
                    // '普通采购','渠道采购'
                    {id:1,name:'普通采购'},
                    {id:2,name:'渠道采购'},
                    {id:3,name:'废铁车采购'},
                ],
                wximagelocalids:[],//判断上传张数
                toast:null,
            }
        },
        mounted () {
            this.wcConfig();
        },
         watch: {
            wximagelocalids:{
                handler(newValue, oldValue){
                     if(newValue.length > 0 ){
                    this.toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "上传中..."
                    });
                    this.wxUpload(newValue[0])
                }else{
                    this.toast.clear();
                }
                },
                deep: true,
            }
        },
        methods: {
            afterDelete(){
                 return new Promise((resolve, reject) => {
                     this.fileList1 = []
                     this.from.image = ''
                    resolve();
                });
                
            },
            uploadershang(file){
                    var toast = Toast.loading({
                        duration: 0, // 持续展示 toast
                        forbidClick: true,
                        message: "加载中..."
                    });
                    let params = new FormData(); //创建form对象
                    params.append("file", file.file);
                    this.$http.post("/index.php/index/upload/index", params)
                    .then((res) => {
                        toast.clear()//清除加载效果
                    if (res.data.code == 0) {
                        this.from.image = res.data.data
                    } else {
                        this.from.image = ''
                        this.fileList1=[]//行驶证正面照片
                        toast.clear()//清除加载效果
                    }
                    }).catch(()=>{
                        this.from.image = ''
                         this.fileList1=[],//行驶证正面照片
                        toast.clear()//清除加载效果
                        this.$toast.fail('网络开小差了，请稍后重试！')
                    })
            },
            //新增渠道取消
            addditch(e){
                if(e){
                   this.from.purchasingChannelsId = e.id
                   this.ChanneNmae = e.shopName
                }
                this.isAddShow = false
            },
            //确定运输批次
            async onSubmit(){
                 try{
                     let data = encipherMent( JSON.stringify(this.from))
                    let param = new URLSearchParams();
                    param.append("value",data);
                    const res = await is_post('index.php/batch/addBatch',param).then(res=>res)
                    this.$toast.success(res.msg)
                    this.$router.push('/Quality')
                }
                catch (err){
                    this.$toast.fail(err.msg)
                }
            },  
            // //查看大图
            bigImage(val){
                let image = [val]
                 ImagePreview({
                    images: image,
                    showIndex: true,
                    loop: false,
                });
            },
            //新增渠道
            addChangeHan(){
                this.isAddShow = true
                this.$nextTick(()=>{
                    this.$refs.Channels.getNmae()
                })
            },
            //采购方式选择
            onConfirm(v,i) {
                this.purchaseName = v.name
                this.from.purchaseType = v.id ;
                if(this.from.purchaseType != 1) {
                    this.getPurchasing()
                }
                this.showPicker = false;
            },
            //采购渠道选择
            onConfirm1(v){
                this.from.purchasingChannelsId = v.id
                this.ChanneNmae = v.shopName
                this.show = false;
            },
            //获取采购渠道
             getPurchasing(){
                this.$http.post('index.php/index/Purchasing_Channels/purchasingChannelsList2').then(res=>{

                    this.ChanneList = res.data.data
                })
            },
        },
    }
</script>

<style lang="scss" scoped>
.content{
    padding: 20px;
    margin-top: 50px;
    .btn-button{
        margin-top: 2rem;
    }
}
.btn-s{
    margin-top: 50px;
}

    .uploaderImg{
        display: inline-block;
        width: 80px ;
        height: 80px ;
        border: 1px solid #eee;
        position: relative;
        .iconz{
            position: absolute;
            top: 42%;
            left: 38%;

        }
    }
 .vanImage{
    position: relative;
    margin-right: 10px;
    margin-top: 10px;
        .iconX{
        position: absolute;
        top: 0;
        right: 0;
        font-size: 25px;
        background: #fff;
        color: #000;
    }
}
</style>